@font-face {
font-family: '__metropolis_73c66b';
src: url(/_next/static/media/654d5d8c13ebad60-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: '__metropolis_73c66b';
src: url(/_next/static/media/95a97a83c412227e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__metropolis_73c66b';
src: url(/_next/static/media/8460ced3d816b784-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__metropolis_73c66b';
src: url(/_next/static/media/d7799d863e25a3ff-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
}@font-face {font-family: '__metropolis_Fallback_73c66b';src: local("Arial");ascent-override: 72.73%;descent-override: 18.75%;line-gap-override: 0.00%;size-adjust: 109.31%
}.__className_73c66b {font-family: '__metropolis_73c66b', '__metropolis_Fallback_73c66b'
}

/* latin-ext */
@font-face {
  font-family: '__Urbanist_fbe417';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/8cdee4d3ed444abc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Urbanist_fbe417';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/01af0fc7b4278e65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Urbanist_Fallback_fbe417';src: local("Arial");ascent-override: 95.09%;descent-override: 25.02%;line-gap-override: 0.00%;size-adjust: 99.91%
}.__className_fbe417 {font-family: '__Urbanist_fbe417', '__Urbanist_Fallback_fbe417';font-style: normal
}

